import { produce } from 'immer';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useAuth } from '@gbs-monorepo-packages/auth';
import {
  Button,
  DefaultDescription,
  type IApiThrowsError,
  type IPaginationMetaProps,
  Logger,
  ManagerRoles,
  Roles,
  TemplateTypeIdList,
  TemplatesType,
  getRouteFrom,
  groupsTypes,
  templatesTypes,
  useToast,
} from '@gbs-monorepo-packages/common';

import { CenteredText } from '../../components/CenteredText';
import { ContentPagination } from '../../components/ContentPagination';
import { DialogModal } from '../../components/DialogModal';
import { CREATE_TEMPLATE } from '../../constants/RoutePaths';
import { type IEditTemplateState } from '../../constants/RouteStates';
import { useCompanies } from '../../hooks/useCompanies';
import {
  type ICreateTemplateGroupProps,
  type IPaginationTemplateGroupsDTO,
  type IPaginationTemplatesDTO,
  type ITemplateDTOWithOrder,
  type ITemplateGroupDTO,
  type ITemplatePageDTO,
  type ITemplatePageWithOrder,
  type IUpdateTemplateGroupProps,
  createTemplateGroup,
  createTemplatePage,
  deletePageTemplateGroup,
  deleteTemplateGroup,
  deleteTemplatePage,
  getTemplateGroupPages,
  getTemplateGroupsByCompanyId,
  getTemplatePagesByCompanyId,
  templateBaseCss,
  templateBaseHtml,
  updateTemplateGroup,
} from '../../services/templates';
import { ByOrder } from '../../utils/sortObjects';
import { SavePageAsTemplateModal } from '../EditCourse/components/SavePageAsTemplateModal';
import { GroupTemplateCard } from './components/GroupTemplateCard';
import { GroupTemplateModal } from './components/GroupTemplateModal';
import { SearchTemplates } from './components/SearchTemplates';
import { TemplateCard } from './components/TemplateCard';
import {
  AccordionRoot,
  ButtonContent,
  GroupTemplateCardContainer,
  Header,
  Loading,
  LoadingContainer,
  MainContainer,
  MainContent,
  NoTemplatesContainer,
  NoTemplatesText,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
  TemplateCardContainer,
  TitlePage,
  UploadButtonContainer,
} from './styles';

export enum TemplatesPageTabs {
  GROUP_TAB = 'group-tab',
  TEMPLATE_TAB = 'template-tab',
}

export interface IGroupCourseTemplateDeletion {
  groupTemplate: ITemplateGroupDTO;
  template: ITemplatePageDTO;
}

const tabParamKey = 'currentTab';
const templateTypeParamKey = 'templateType';
const courseTemplateTypeParamKey = 'courseTemplateType';
const selectedCourseTemplateParamKey = 'selectedCourseTemplate';
const searchParamKey = 'templateSearch';

export const TemplateCourses = (): JSX.Element => {
  const { selectedCompany } = useCompanies();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const { user, getCurrentRole } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();

  const paginationLimit = 15;

  const requestSelectedTab =
    searchParams.get(tabParamKey) &&
    searchParams.get(tabParamKey) === TemplatesPageTabs.GROUP_TAB
      ? TemplatesPageTabs.GROUP_TAB
      : TemplatesPageTabs.TEMPLATE_TAB;

  const requestTemplateType =
    searchParams.get(templateTypeParamKey) !== null &&
    TemplateTypeIdList.includes(Number(searchParams.get(templateTypeParamKey)))
      ? Number(searchParams.get(templateTypeParamKey))
      : TemplatesType.GLOBAL_TEMPLATES;

  const requestCourseTemplateType =
    searchParams.get(courseTemplateTypeParamKey) !== null &&
    TemplateTypeIdList.includes(
      Number(searchParams.get(courseTemplateTypeParamKey))
    )
      ? Number(searchParams.get(courseTemplateTypeParamKey))
      : TemplatesType.GLOBAL_TEMPLATES;

  const [selectedCoursePage, setSelectedCoursePage] = useState('');

  const [currentTab, setCurrentTab] =
    useState<TemplatesPageTabs>(requestSelectedTab);

  const templateTypes = templatesTypes.filter(
    (i) => i.key !== TemplatesType.BUILD_SCRATCH
  );

  const [currentTemplateType, setCurrentTemplateType] =
    useState<TemplatesType>(requestTemplateType);
  const [currentTemplateSearch, setCurrentTemplateSearch] = useState(
    currentTab === TemplatesPageTabs.TEMPLATE_TAB
      ? searchParams.get(searchParamKey) ?? ''
      : ''
  );
  const lastSearchTemplate = useRef(currentTemplateSearch);
  const [isSearchingTemplates, setIsSearchingTemplates] = useState(true);
  const [templates, setTemplates] = useState<ITemplatePageDTO[]>([]);
  const [templatesPaginationMeta, setTemplatesPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [isModalSaveTemplateOpen, setIsModalSaveTemplateOpen] = useState(false);
  const [isLoadingSaveTemplate, setIsLoadingSaveTemplate] = useState(false);
  const [templateToDelete, setTemplateToDelete] =
    useState<ITemplatePageDTO | null>(null);
  const [isLoadingDeleteTemplate, setIsLoadingDeleteTemplate] = useState(false);

  useEffect(() => {
    if (isSearchingTemplates) {
      return;
    }

    let timer: NodeJS.Timeout | null = null;

    if (currentTemplateSearch.trim() !== lastSearchTemplate.current) {
      lastSearchTemplate.current = currentTemplateSearch;

      if (currentTemplateSearch.trim() === '') {
        setIsSearchingTemplates(true);

        void searchTemplateData();
      } else {
        timer = setTimeout(() => {
          setIsSearchingTemplates(true);

          void searchTemplateData();
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentTemplateSearch]);

  const isUserManager = useMemo(() => {
    const currentRole = getCurrentRole(user?.roles ?? []);
    return (
      (currentRole?.key && ManagerRoles.includes(currentRole.key)) ?? false
    );
  }, [getCurrentRole, user]);

  const handleTemplateTypeChange = useCallback(
    (selectedType: number) => {
      setIsSearchingTemplates(true);
      setCurrentTemplateSearch('');
      lastSearchTemplate.current = '';
      setCurrentTemplateType(selectedType);
    },
    [setCurrentTemplateType]
  );

  const handleSaveTemplate = useCallback(
    async (title: string, templateOptions: number) => {
      if (title === '') {
        addToast({
          title: 'Error while saving the template',
          description: 'The title is empty.',
          styleType: 'error',
          dataCy: 'save-template-description-error-toast',
        });
        return;
      }

      setIsLoadingSaveTemplate(true);

      await createTemplatePage({
        companyId: selectedCompany?.id ?? 0,
        cssContent: templateBaseCss,
        hasWrapper: true,
        htmlContent: templateBaseHtml,
        templateOptions,
        title,
        fonts: [],
        components: '{}',
      })
        .then((response) => {
          const state: IEditTemplateState = {
            from: pathname,
          };

          navigate(
            generatePath(getRouteFrom(CREATE_TEMPLATE), {
              templateId: response.id.toString(),
              companyId: (selectedCompany?.id ?? 0).toString(),
            }),
            { state }
          );

          addToast({
            title: 'Template saved',
            styleType: 'success',
            dataCy: 'save-template-success-toast',
          });
        })
        .catch((err) => {
          Logger.error('Failed to save the template', err);

          addToast({
            title: 'Error while saving the template',
            description: DefaultDescription,
            styleType: 'error',
            dataCy: 'save-template-error-toast',
          });
        })
        .finally(() => {
          setIsLoadingSaveTemplate(false);
        });
    },
    [addToast, navigate, pathname, selectedCompany?.id]
  );

  const handleEditTemplate = (template: ITemplatePageDTO) => {
    const state: IEditTemplateState = {
      from: pathname,
    };

    navigate(
      generatePath(getRouteFrom(CREATE_TEMPLATE), {
        templateId: template.id.toString(),
        companyId: (selectedCompany?.id ?? 0).toString(),
      }),
      { state }
    );
  };

  const handleDeleteTemplate = () => {
    if (templateToDelete === null) {
      addToast({
        title: 'Failed to delete the Template',
        description: 'The Template data could not be found',
        styleType: 'error',
        dataCy: 'template-delete-empty-data-toast',
      });
      return;
    }

    setIsLoadingDeleteTemplate(true);

    deleteTemplatePage({ id: templateToDelete.id })
      .then(() => {
        setTemplates([]);
        setIsSearchingTemplates(true);
        void searchTemplateData();

        addToast({
          title: 'Template deleted successfully',
          description: 'The template has been successfully deleted.',
          styleType: 'success',
          dataCy: 'delete-template-success-toast',
        });
      })
      .catch((err: IApiThrowsError) => {
        addToast({
          title: 'Error while deleting the template',
          description:
            err.response?.status === 400
              ? err.response.data.error.message
              : DefaultDescription,
          styleType: 'error',
          dataCy: 'delete-template-error-toast',
        });
      })
      .finally(() => {
        setIsLoadingDeleteTemplate(false);
        setTemplateToDelete(null);
      });
  };

  const [currentCourseTemplateType, setCurrentCourseTemplateType] =
    useState<TemplatesType>(requestCourseTemplateType);
  const [currentCourseTemplateSearch, setCurrentCourseTemplateSearch] =
    useState(
      currentTab === TemplatesPageTabs.GROUP_TAB
        ? searchParams.get(searchParamKey) ?? ''
        : ''
    );
  const lastSearchCoursetemplate = useRef(currentCourseTemplateSearch);
  const [isSearchingCourseTemplates, setIsSearchingCourseTemplates] =
    useState(true);
  const [courseTemplates, setCourseTemplates] = useState<ITemplateGroupDTO[]>(
    []
  );
  const [courseTemplatesPaginationMeta, setCourseTemplatesPaginationMeta] =
    useState<IPaginationMetaProps | null>(null);
  const [isModalGroupTemplateOpen, setIsModalGroupTemplateOpen] =
    useState(false);
  const [groupTemplateToUpdate, setGroupTemplateToUpdate] =
    useState<ITemplateGroupDTO | null>(null);
  const [courseTemplateTemplates, setCourseTemplateTemplates] = useState<
    ITemplatePageWithOrder[] | null
  >(null);
  const [groupTemplateToDelete, setGroupTemplateToDelete] =
    useState<ITemplateGroupDTO | null>(null);
  const [isLoadingDeleteGroupTemplate, setIsLoadingDeleteGroupTemplate] =
    useState(false);
  const [groupTemplateTemplateToDelete, setGroupTemplateTemplateToDelete] =
    useState<IGroupCourseTemplateDeletion | null>(null);
  const [isLoadingGroupTemplateModal, setIsLoadingGroupTemplateModal] =
    useState(false);

  const handleCourseTemplateTypeChange = useCallback(
    (selectedType: number) => {
      lastSearchCoursetemplate.current = '';
      searchParams.delete(selectedCourseTemplateParamKey);

      setIsSearchingCourseTemplates(true);
      setCurrentCourseTemplateSearch('');
      setSearchParams(searchParams);
      setCurrentCourseTemplateType(selectedType);
      setSelectedCoursePage('');
    },
    [setCurrentCourseTemplateType]
  );

  useEffect(() => {
    if (isSearchingCourseTemplates) {
      return;
    }

    let timer: NodeJS.Timeout | null = null;

    if (
      currentCourseTemplateSearch.trim() !== lastSearchCoursetemplate.current
    ) {
      lastSearchCoursetemplate.current = currentCourseTemplateSearch;

      if (currentCourseTemplateSearch.trim() === '') {
        setIsSearchingCourseTemplates(true);

        void searchCourseTemplateData();
      } else {
        timer = setTimeout(() => {
          setIsSearchingCourseTemplates(true);

          void searchCourseTemplateData();
        }, 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [currentCourseTemplateSearch]);

  const handleAcceptCreateGroupTemplate = (
    groupTemplate: ICreateTemplateGroupProps
  ) => {
    setIsLoadingGroupTemplateModal(true);

    createTemplateGroup({
      client: groupTemplate.client,
      title: groupTemplate.title,
      templateOptions: groupTemplate.templateOptions,
      pageTemplates: groupTemplate.pageTemplates,
    })
      .then((response) => {
        if (response.id) {
          addToast({
            title: 'Template Course created',
            styleType: 'success',
            dataCy: 'save-template-success-toast',
          });
        }

        setIsLoadingGroupTemplateModal(false);
        setIsModalGroupTemplateOpen(false);
        setIsSearchingCourseTemplates(true);

        void searchCourseTemplateData();
      })
      .catch((err) => {
        Logger.debug('error: ', err);
        addToast({
          title: 'Error on create course',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'create-course-from-template-error-toast',
        });
      });
  };

  const handleAcceptUpdateGroupTemplate = (
    groupTemplate: IUpdateTemplateGroupProps
  ) => {
    setIsLoadingGroupTemplateModal(true);

    updateTemplateGroup(groupTemplate)
      .then((response) => {
        if (response.id) {
          addToast({
            title: 'Template Course updated',
            styleType: 'success',
            dataCy: 'update-template-success-toast',
          });
        }
      })
      .catch((err) => {
        Logger.debug('error: ', err);
        addToast({
          title: 'Error while updating course',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'update-course-from-template-error-toast',
        });
      })
      .finally(() => {
        setIsLoadingGroupTemplateModal(false);
        setIsModalGroupTemplateOpen(false);
        setGroupTemplateToUpdate(null);
        setIsSearchingCourseTemplates(true);

        void searchCourseTemplateData();
      });
  };

  const handleDeclineGroupTemplate = () => {
    setIsModalGroupTemplateOpen(false);
    setGroupTemplateToUpdate(null);
  };

  const handleSearchGroupTemplateTemplates = useCallback(
    async (groupId: string) => {
      setCourseTemplateTemplates(null);

      if (groupId !== '') {
        const response = await getTemplateGroupPages({
          templateGroupId: Number(groupId),
        });

        const templatesWithOrder = response.data.map(
          (template): ITemplateDTOWithOrder => {
            const order = template.courseTemplatePageOrders?.find(
              (groupsOrder) => {
                return groupsOrder.courseTemplate.id === Number(groupId);
              }
            );

            return {
              ...template,
              order: order ? order.pageOrder : 0,
            };
          }
        );

        templatesWithOrder.sort(ByOrder<ITemplateDTOWithOrder>);

        setCourseTemplateTemplates(templatesWithOrder);
      }
    },
    []
  );

  const handleDeleteGroupTemplate = () => {
    setIsLoadingDeleteGroupTemplate(true);

    void deleteTemplateGroup({ id: groupTemplateToDelete?.id ?? 0 })
      .then(() => {
        addToast({
          title: 'Course Template deleted',
          styleType: 'success',
          dataCy: 'delete-template-success-toast',
        });

        setIsSearchingCourseTemplates(true);
        void searchCourseTemplateData();
      })
      .catch((err) => {
        Logger.debug('error: ', err);
        addToast({
          title: 'Error on the delete course template',
          description: DefaultDescription,
          styleType: 'error',
          dataCy: 'delete-template-error-toast',
        });
      })
      .finally(() => {
        setGroupTemplateToDelete(null);
        setIsLoadingDeleteGroupTemplate(false);
      });
  };

  const handleDeleteGroupTemplatesTemplate = (
    groupTemplate: ITemplateGroupDTO,
    template: ITemplatePageDTO
  ) => {
    setIsLoadingDeleteTemplate(true);
    deletePageTemplateGroup({
      id: groupTemplate.id,
      pageTemplateId: template.id,
    })
      .then(() => {
        void handleSearchGroupTemplateTemplates(groupTemplate.id.toString());

        addToast({
          title: 'The template has been successfully removed.',
          description: `Template removed from the ${groupTemplate.title} Course Template successfully`,
          styleType: 'success',
          dataCy: 'delete-group-template-template-success-toast',
        });
      })
      .catch((err: IApiThrowsError) => {
        addToast({
          title: 'Error while removing the Template from the Course Template',
          description:
            err.response?.status === 400
              ? err.response.data.error.message
              : DefaultDescription,
          styleType: 'error',
          dataCy: 'delete-group-template-template-error-toast',
        });
      })
      .finally(() => {
        setGroupTemplateTemplateToDelete(null);
        setIsLoadingDeleteTemplate(false);
      });
  };

  const handleTabClick = useCallback(
    (value: string) => {
      switch (value) {
        case TemplatesPageTabs.TEMPLATE_TAB:
          setCurrentCourseTemplateType(TemplatesType.GLOBAL_TEMPLATES);
          setCurrentCourseTemplateSearch('');
          lastSearchTemplate.current = '';
          setCourseTemplates([]);
          setCourseTemplatesPaginationMeta(null);
          setIsSearchingCourseTemplates(true);
          setSelectedCoursePage('');
          break;

        case TemplatesPageTabs.GROUP_TAB:
          setCurrentTemplateType(TemplatesType.GLOBAL_TEMPLATES);
          setCurrentTemplateSearch('');
          lastSearchCoursetemplate.current = '';
          setTemplates([]);
          setTemplatesPaginationMeta(null);
          setIsSearchingTemplates(true);
          setSelectedCoursePage('');
          break;

        default:
          Logger.error('Invalid Tab selected.');
          return;
      }

      setCurrentTab(value);
    },
    [setCurrentTab]
  );

  const searchTemplateData = useCallback(
    async (incrementPage = false) => {
      let currentPage = 1;

      if (incrementPage && templatesPaginationMeta) {
        currentPage = templatesPaginationMeta.page + 1;
      }

      searchParams.set(tabParamKey, TemplatesPageTabs.TEMPLATE_TAB);
      searchParams.delete(courseTemplateTypeParamKey);
      searchParams.delete(selectedCourseTemplateParamKey);
      searchParams.set(templateTypeParamKey, currentTemplateType.toString());
      currentTemplateSearch
        ? searchParams.set(searchParamKey, currentTemplateSearch)
        : searchParams.delete(searchParamKey);

      setSearchParams(searchParams);

      await getTemplatePagesByCompanyId({
        clientId: selectedCompany?.id ?? 0,
        templateOptionsId: currentTemplateType,
        page: currentPage,
        limit: paginationLimit,
        filter: currentTemplateSearch,
      })
        .then((data: IPaginationTemplatesDTO) => {
          setTemplates(
            incrementPage
              ? produce((draft) => {
                  draft.push(...data.data);
                })
              : data.data
          );
          setTemplatesPaginationMeta(data.meta);
        })
        .catch((err) => {
          Logger.error('Failed to search the Templates', err);
        })
        .finally(() => {
          setIsSearchingTemplates(false);
        });
    },
    [
      templatesPaginationMeta,
      searchParams,
      currentTemplateType,
      currentTemplateSearch,
      setSearchParams,
      selectedCompany?.id,
    ]
  );

  const searchCourseTemplateData = useCallback(
    async (incrementPage = false) => {
      let currentPage = 1;

      if (incrementPage && courseTemplatesPaginationMeta) {
        currentPage = courseTemplatesPaginationMeta.page + 1;
      }

      searchParams.set(tabParamKey, TemplatesPageTabs.GROUP_TAB);
      searchParams.delete(templateTypeParamKey);
      searchParams.set(
        courseTemplateTypeParamKey,
        currentCourseTemplateType.toString()
      );
      currentCourseTemplateSearch
        ? searchParams.set(searchParamKey, currentCourseTemplateSearch)
        : searchParams.delete(searchParamKey);

      setSearchParams(searchParams);

      const viewedCourseTemplate = searchParams.get(
        selectedCourseTemplateParamKey
      );

      await getTemplateGroupsByCompanyId({
        templateOptionsId: currentCourseTemplateType,
        page: currentPage,
        limit: paginationLimit,
        filter: currentCourseTemplateSearch,
      })
        .then((data: IPaginationTemplateGroupsDTO) => {
          setCourseTemplates(
            incrementPage
              ? produce((draft) => {
                  draft.push(...data.data);
                })
              : data.data
          );
          setCourseTemplatesPaginationMeta(data.meta);
        })
        .catch((err) => {
          Logger.error('Failed to search the Course Templates', err);
        })
        .finally(() => {
          setIsSearchingCourseTemplates(false);

          if (
            selectedCoursePage === '' &&
            viewedCourseTemplate !== null &&
            viewedCourseTemplate !== '' &&
            viewedCourseTemplate !== selectedCoursePage
          ) {
            setSelectedCoursePage(viewedCourseTemplate);

            void handleSearchGroupTemplateTemplates(viewedCourseTemplate);

            const element = document.querySelector(
              `[data-cy="group-template-${viewedCourseTemplate}-accordion-content"]`
            );

            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        });
    },
    [
      courseTemplatesPaginationMeta,
      currentCourseTemplateSearch,
      currentCourseTemplateType,
      handleSearchGroupTemplateTemplates,
      searchParams,
      selectedCoursePage,
      setSearchParams,
    ]
  );

  const searchTabData = useCallback(() => {
    switch (currentTab) {
      case TemplatesPageTabs.TEMPLATE_TAB:
        void searchTemplateData();
        break;

      case TemplatesPageTabs.GROUP_TAB:
        void searchCourseTemplateData();
        break;

      default:
        Logger.error('Invalid Tab selected.');
    }
  }, [currentTab, searchCourseTemplateData, searchTemplateData]);

  useEffect(() => {
    searchTabData();
  }, [
    currentTab,
    selectedCompany,
    currentTemplateType,
    currentCourseTemplateType,
  ]);

  return (
    <MainContainer data-cy="templates-page-container">
      <TabsRoot value={currentTab} onValueChange={handleTabClick}>
        <TabsList aria-label="Choose a image">
          <TabsTrigger value="template-tab" data-cy="template-tab">
            Templates
          </TabsTrigger>
          <TabsTrigger value="group-tab" data-cy="group-tab">
            Course Templates
          </TabsTrigger>
        </TabsList>

        <TabsContent value="template-tab" data-cy="template-tab-container">
          <Header>
            <TitlePage data-cy="template-tab-page-title">Templates</TitlePage>
            <UploadButtonContainer data-cy="template-tab-page-add-button-container">
              <Button
                dataCy="template-tab-page-dropdown-add-button"
                onClick={() => {
                  setIsModalSaveTemplateOpen(true);
                }}
              >
                <ButtonContent>
                  <p data-cy="template-tab-page-button-add">Add Template</p>
                </ButtonContent>
              </Button>
            </UploadButtonContainer>
          </Header>

          <MainContent>
            <SearchTemplates
              dataCyPrefix="template"
              labelPrefix="Template"
              templateTypeData={templateTypes}
              currentTemplateType={currentTemplateType}
              onChangeTemplateType={handleTemplateTypeChange}
              currentSearch={currentTemplateSearch}
              onChangeSearchData={(search) => {
                setCurrentTemplateSearch(search);
              }}
              onClearSearchData={() => {
                setCurrentTemplateSearch('');
              }}
              isSearching={isSearchingTemplates}
            />

            <ContentPagination data-cy="template-tab-page-pagination-content">
              {isSearchingTemplates && (
                <LoadingContainer data-cy="loading-templates-container">
                  <Loading dataCy="loading-templates-label" />
                </LoadingContainer>
              )}

              {!isSearchingTemplates && templates.length === 0 && (
                <NoTemplatesContainer data-cy="no-templates-container">
                  <NoTemplatesText data-cy="no-templates-label">
                    No Templates found
                  </NoTemplatesText>
                </NoTemplatesContainer>
              )}

              {!isSearchingTemplates &&
                templates.length > 0 &&
                templatesPaginationMeta && (
                  <InfiniteScroll
                    height={200}
                    style={{
                      overflow: 'auto',
                      maxHeight: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}
                    dataLength={templates.length} // This is important field to render the next data
                    next={async () => {
                      await searchTemplateData(true);
                    }}
                    hasMore={
                      templates.length < (templatesPaginationMeta?.total ?? 0)
                    }
                    loader={
                      <LoadingContainer data-cy="loading-templates-container">
                        <Loading dataCy="loading-templates-label" />
                      </LoadingContainer>
                    }
                    endMessage={
                      <CenteredText
                        message="You have seen it all"
                        dataCy="pagination-end-templates"
                      />
                    }
                  >
                    {templates.map((template) => {
                      return (
                        <TemplateCardContainer
                          key={`template-${template.id}-container`}
                          data-cy={`template-${template.id}-container`}
                        >
                          <TemplateCard
                            templateData={template}
                            onEditClick={handleEditTemplate}
                            onDeleteClick={setTemplateToDelete}
                          />
                        </TemplateCardContainer>
                      );
                    })}
                  </InfiniteScroll>
                )}
            </ContentPagination>
          </MainContent>
        </TabsContent>

        <TabsContent value="group-tab" data-cy="group-tab-container">
          <Header>
            <TitlePage data-cy="group-tab-page-title">
              Course Templates
            </TitlePage>
            <UploadButtonContainer data-cy="group-tab-page-add-button-container">
              <Button
                dataCy="group-tab-page-dropdown-add-button"
                onClick={() => {
                  setIsModalGroupTemplateOpen(true);
                }}
              >
                <ButtonContent>
                  <p data-cy="group-tab-page-button-add">New Course Template</p>
                </ButtonContent>
              </Button>
            </UploadButtonContainer>
          </Header>

          <MainContent>
            <SearchTemplates
              dataCyPrefix="courseTemplate"
              labelPrefix="Course Template"
              templateTypeData={groupsTypes}
              currentTemplateType={currentCourseTemplateType}
              onChangeTemplateType={handleCourseTemplateTypeChange}
              currentSearch={currentCourseTemplateSearch}
              onChangeSearchData={setCurrentCourseTemplateSearch}
              onClearSearchData={() => {
                setCurrentCourseTemplateSearch('');
              }}
            />

            <ContentPagination>
              {isSearchingCourseTemplates && (
                <LoadingContainer data-cy="loading-templates-container">
                  <Loading dataCy="loading-templates-label" />
                </LoadingContainer>
              )}

              {!isSearchingCourseTemplates && courseTemplates.length === 0 && (
                <NoTemplatesContainer data-cy="no-templates-container">
                  <NoTemplatesText data-cy="no-templates-label">
                    No Course Templates found
                  </NoTemplatesText>
                </NoTemplatesContainer>
              )}

              {!isSearchingCourseTemplates &&
                courseTemplates.length > 0 &&
                courseTemplatesPaginationMeta && (
                  <AccordionRoot
                    type="single"
                    collapsible
                    onValueChange={(groupId) => {
                      void handleSearchGroupTemplateTemplates(groupId);

                      setSelectedCoursePage(groupId);

                      searchParams.set(selectedCourseTemplateParamKey, groupId);
                      setSearchParams(searchParams);
                    }}
                    value={selectedCoursePage}
                  >
                    <InfiniteScroll
                      height={200}
                      style={{
                        overflow: 'auto',
                        maxHeight: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                      }}
                      dataLength={courseTemplates.length}
                      next={() => {
                        void searchCourseTemplateData(true);
                      }}
                      hasMore={
                        courseTemplates.length <
                        (courseTemplatesPaginationMeta?.total ?? 0)
                      }
                      loader={
                        <LoadingContainer data-cy="loading-templates-container">
                          <Loading dataCy="loading-templates-label" />
                        </LoadingContainer>
                      }
                      endMessage={
                        <CenteredText
                          message="You have seen it all"
                          dataCy="pagination-end-group-templates"
                        />
                      }
                    >
                      {courseTemplates.map((grouptemplate) => {
                        return (
                          <GroupTemplateCardContainer
                            key={`group-template-${grouptemplate.id}-container`}
                            data-cy={`group-template-${grouptemplate.id}-container`}
                          >
                            <GroupTemplateCard
                              groupTemplate={grouptemplate}
                              templates={courseTemplateTemplates}
                              onEditGroupClick={(groupToUpdate) => {
                                setGroupTemplateToUpdate(groupToUpdate);
                                setIsModalGroupTemplateOpen(true);
                              }}
                              onDeleteGroupClick={setGroupTemplateToDelete}
                              onEditTemplateClick={(
                                groupTemplate,
                                template
                              ) => {
                                handleEditTemplate(template);
                              }}
                              onDeleTeTemplateClick={(
                                currentGroup,
                                currentTemplate
                              ) => {
                                setGroupTemplateTemplateToDelete({
                                  groupTemplate: currentGroup,
                                  template: currentTemplate,
                                });
                              }}
                            />
                          </GroupTemplateCardContainer>
                        );
                      })}
                    </InfiniteScroll>
                  </AccordionRoot>
                )}
            </ContentPagination>
          </MainContent>
        </TabsContent>
      </TabsRoot>

      {isModalSaveTemplateOpen && (
        <SavePageAsTemplateModal
          loading={isLoadingSaveTemplate}
          onAccept={handleSaveTemplate}
          onDecline={() => {
            setIsModalSaveTemplateOpen(false);
          }}
          isUserAdmin={getCurrentRole(user?.roles ?? [])?.key === Roles.ADMIN}
          isUserManager={isUserManager}
          open
        />
      )}

      {templateToDelete && (
        <DialogModal
          acceptText="Confirm"
          declineText="Cancel"
          mainText={`Are you sure you want to delete ${
            templateToDelete.title !== ''
              ? 'the ' + templateToDelete.title + ' template'
              : 'this template'
          }?`}
          onAccept={handleDeleteTemplate}
          onDecline={() => {
            setTemplateToDelete(null);
          }}
          onOpenChange={() => {
            setTemplateToDelete(null);
          }}
          loading={isLoadingDeleteTemplate}
          open
        />
      )}

      {isModalGroupTemplateOpen && (
        <GroupTemplateModal
          groupTemplateToUpdate={groupTemplateToUpdate}
          isLoadingAccept={isLoadingGroupTemplateModal}
          isUserAdmin={getCurrentRole(user?.roles ?? [])?.key === Roles.ADMIN}
          isUserManager={isUserManager}
          onAcceptCreate={handleAcceptCreateGroupTemplate}
          onAcceptUpdate={handleAcceptUpdateGroupTemplate}
          onDecline={handleDeclineGroupTemplate}
        />
      )}

      {groupTemplateTemplateToDelete && (
        <DialogModal
          acceptText="Confirm"
          declineText="Cancel"
          mainText={`Are you sure you want to removed ${
            groupTemplateTemplateToDelete.template.title !== ''
              ? 'the ' +
                groupTemplateTemplateToDelete.template.title +
                ' template'
              : 'this template'
          } from the ${
            groupTemplateTemplateToDelete.groupTemplate.title === ''
              ? 'current '
              : groupTemplateTemplateToDelete.groupTemplate.title
          } Course Template?`}
          onAccept={() => {
            handleDeleteGroupTemplatesTemplate(
              groupTemplateTemplateToDelete.groupTemplate,
              groupTemplateTemplateToDelete.template
            );
          }}
          onDecline={() => {
            setGroupTemplateTemplateToDelete(null);
          }}
          onOpenChange={() => {
            setGroupTemplateTemplateToDelete(null);
          }}
          loading={isLoadingDeleteTemplate}
          open
        />
      )}

      {groupTemplateToDelete && (
        <DialogModal
          acceptText="Confirm"
          declineText="Cancel"
          mainText={`Are you sure you want to delete ${
            groupTemplateToDelete.title === ''
              ? 'this'
              : 'the ' + groupTemplateToDelete.title
          } Course Template?`}
          onAccept={handleDeleteGroupTemplate}
          onDecline={() => {
            setGroupTemplateToDelete(null);
          }}
          onOpenChange={() => {
            setGroupTemplateToDelete(null);
          }}
          loading={isLoadingDeleteGroupTemplate}
          open
        />
      )}
    </MainContainer>
  );
};
